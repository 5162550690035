import React from 'react'
import {Link} from "gatsby"

const BlogItem = ({slug, title, excerpt, tag}) => {
    return (
        <article className="mb-12 pb-12 border-b border-solid border-gray-400">
            <Link className="group" to={`/${slug}`}>
                <p className="text-gray-700 text-xs md:text-sm lg:text-base mb-2">{tag}</p>
                <h3 className="font-semibold group-hover:text-orange-500 text-blue-500 transition-colors duration-200 ease-in-out mb-2 capitalize text-lg sm:text-xl md:text-3xl">{title}</h3>
                <p className="prose lg:prose-xl">{excerpt}</p>
            </Link>
        </article>
    )
}

export default BlogItem
