import React from 'react'

const Newsletter = () => {

    function handleSubmit(e) {
        e.preventDefault();
        const status = document.getElementById('status');
        const mail = document.getElementById('mail');
        const url = `https://api.espresso-tutorials.com/api/newsletter/subscribe?locale=de`;

        if (status) {
            status.innerText = '';
            status.classList.remove('text-red-500');
        }

        if (!mail || !mail?.value) {
            status.innerText = 'Fehler: Bitte überprüfen Sie ihre Angaben und versuchen Sie es erneut!';
            status.classList.add('text-red-500');
            return;
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ email: mail.value }),
        }).then((response) => {
            if (response.ok) {
                return response;
            }
            return Promise.reject(response);
        }).then(() => {
            status.innerText = 'Vielen Dank, Sie haben sich erfolgreich zum Newsletter angemeldet.';
            status.classList.add('text-green-500');
            mail.value = '';
            setTimeout(() => {
                status.innerText = '';
                status.classList.remove('text-green-500');
            }, 5000);
        }).catch((error) => {
            console.log(error);
            status.innerText = 'Fehler: Bitte überprüfen Sie ihre Angaben und versuchen Sie es erneut!';
            status.classList.add('text-red-500');
            setTimeout(() => {
                status.innerText = '';
                status.classList.remove('text-red-500');
            }, 5000);
        });
    }

    return (
        <div className="mt-16 bg-blue-200 rounded-lg md:rounded-xl p-4 md:p-8">
            <h2 className="text-2xl text-gray-900 leading-tight">Für den Newsletter anmelden und nie mehr Neuerungen verpassen!</h2>
            <form className="mt-8" onSubmit={handleSubmit}>
                <div className="">
                    <label className="sr-only" htmlFor="email">E-Mail Adresse</label>
                    <div className="flex flex-row items-center">
                        <input id="mail"
                               className="flex-1 appearance-none text-sm py-3 focus:outline-none rounded-lg bg-white text-gray-800 px-4 placeholder-gray-600"
                               type="text" placeholder="E-Mail Adresse"/>
                        <button type="submit" className="w-32 text-center ml-4 appearance-none bg-blue-500 hover:bg-orange-500 font-semibold text-white leading-none p-4 rounded-lg text-center text-sm cursor-pointer">
                            Abonnieren
                        </button>
                    </div>
                </div>
            </form>
            <div id="status" className="w-full text-sm font-semibold"></div>
        </div>
    )
}

export default Newsletter
